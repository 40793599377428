<template>
  <div class="chat">

    <template v-if="msg.message1answer == null">
      <div class="blue messages">

        <div class="message last" v-html="$parent.info.license.gameText[$parent.info.chosenLanguage].messages.message1">
        </div>

      </div>
      <template v-if="msg.message1 != null">
        <div class="grey messages">
          <div v-if="message1answer == 0" class="message last"
            v-html="$parent.info.license.gameText[$parent.info.chosenLanguage].messages.safe">

          </div>
          <div v-else class="message last"
            v-html="$parent.info.license.gameText[$parent.info.chosenLanguage].messages.notsafe">

          </div>
        </div>
      </template>
    </template>



    <template v-if="msg.message1answer != null && msg.message2answer == null">
      <div class="blue messages">
        <div class="message last" v-html="$parent.info.license.gameText[$parent.info.chosenLanguage].messages.message2">
        </div>
      </div>

      <template v-if="msg.message2 != null">
        <div class="grey messages">
          <div v-if="message2answer == 0" class="message last">
            Safe
          </div>
          <div v-else class="message last">
            Not Safe
          </div>
        </div>
      </template>
    </template>



    <template v-if="msg.message3answer == null && msg.message1answer != null && msg.message2answer != null">
      <div class="blue messages">
        <div class="message last" v-html="$parent.info.license.gameText[$parent.info.chosenLanguage].messages.message3">
        </div>
      </div>

      <template v-if="msg.message3 != null">
        <div class="grey messages">
          <div v-if="mmessage3answer == 0" class="message last">
            Safe
          </div>
          <div v-else class="message last">
            Not Safe
          </div>
        </div>
      </template>

    </template>



    <div id="buttons">
      <input type="button" v-model="$parent.info.license.gameText[$parent.info.chosenLanguage].messages.safe"
        class="choiseButtons safe" @click="messagechoice('safe')">
      <input type="button" v-model="$parent.info.license.gameText[$parent.info.chosenLanguage].messages.notsafe"
        class="button-margin choiseButtons notsafe" @click="messagechoice('notsafe', count)">
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import { defineComponent } from 'vue'
import { callApi } from '../plugins/callApi.js'

export default defineComponent({
  name: 'MessageApp',
  props: {
    msg: Object,
    msgCount: Number,
    lic: String
  },
  data() {
    return {
      count: 1,
      mmessage1answer: 0,
      mmessage2answer: 0,
      mmessage3answer: 0,
    }
  },
  methods: {
    async messagechoice(msg) {
      console.log(this.msg)
      console.log(typeof this.msg.message1answer, "1")
      console.log(typeof this.msg.message2answer, "2")
      console.log(typeof this.msg.message3answer, "3")
      let answer = 0;
      if (this.count == 4) {
        this.count = 1
      }

      var msgname = ""
      if (typeof this.msg.message1answer == "undefined") {
        msgname = "message1"
        if (msg == "safe") {
          this.mmessage1answer = 0
        } else {
          this.mmessage1answer = 1
        }
        answer = this.mmessage1answer
        // this.mmessage1answer = msg == "safe" ? 0 : 1;
      }

      if (typeof this.msg.message1answer !== "undefined" && typeof this.msg.message2answer === "undefined") {
        msgname = "message2"
        if (msg == "safe") {
          this.mmessage2answer = 0
        } else {
          this.mmessage2answer = 1
        }
        answer = this.mmessage2answer
        // this.mmessage2answer = msg == "safe" ? 0 : 1;

      } else {
        console.log(typeof this.msg.message2answer)
      }

      if (typeof this.msg.message1answer !== "undefined" && typeof this.msg.message2answer !== "undefined" && typeof this.msg.message3answer === "undefined") {
        msgname = "message3"
        if (msg == "safe") {
          this.mmessage3answer = 0
        } else {
          this.mmessage3answer = 1
        }
        answer = this.mmessage3answer
        // this.mmessage3answer = msg == "safe" ? 0 : 1;
      }


      console.log({ "message": msgname, "answer": answer, "license": localStorage.getItem('license'), "boxid": localStorage.getItem('boxid') })
      return await callApi("messagesgame", this.lic, { "message": msgname + "answer", "answer": answer, "license": this.lic, "boxid": localStorage.getItem('boxid'), "type": "update" })

    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#buttons {
  position: absolute;
  bottom: 50px;
  width: 100%;
  text-align: center;
}

.safe {
  background-color: green;
  color: white;
}

.notsafe {
  background-color: red;
  color: white;
}


h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

body {
  font-family: helvetica;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.chat {
  border: solid 1px #EEE;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.messages {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  font-size: 25px;
}

.grey {
  align-items: flex-start;
}

.grey .message {
  margin-right: 25%;
  background-color: #eee;
  position: relative;
}

.grey .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}

.grey .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.blue {
  align-items: flex-end;
}

.blue .message {
  color: white;
  margin-left: 50%;
  background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
  background-attachment: fixed;
  position: relative;
}

.blue .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.blue .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}

.choiseButtons {
  align-items: center;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  font-size: 30px;
}

.button-margin {
  margin-left: 20px;

}
</style>
